.slick-slide img,
.slick-slide video {
  transition: transform 0.3s ease;
}

.slick-slide:hover img,
.slick-slide:hover video {
  transform: scale(1.05);
}

.slick-prev,
.slick-next {
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px;
  color: #126F43;
}

.image-container,
.video-container {
  width: 100%;
  height: 400px; 
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
  }

  .image-container,
  .video-container {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .image-container,
  .video-container {
    height: 300px !important; 
  }
}
